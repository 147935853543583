<template>
  <div>
    <p class="text-static-default-low body-1 mt-12 whitespace-pre-wrap">
      {{ i18n(translations.identityInformationsChoiceText) }}
    </p>

    <div class="mt-32">
      <RevRadio
        v-for="option in ids"
        :id="option.id"
        :key="option.id"
        v-model="radioId as string"
        class="mt-16"
        :value="option.id"
        variant="full"
      >
        <template #label>{{ option.label }}</template>
      </RevRadio>

      <RevInfoBlock
        class="mt-32 whitespace-pre-wrap"
        :content="i18n(translations.choiceInfoBlockContent)"
        :icon="IconLock"
        :title="i18n(translations.choiceInfoBlockTitle)"
        variant="default"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { PaymentInfosOptionsType } from '@backmarket/http-api/src/api-specs-buyback/customer/client'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { RevRadio } from '@ds/components/Radio'
import { IconLock } from '@ds/icons/IconLock'

import translations from './IdentityInformations.translations'

defineProps<{
  ids: { id: PaymentInfosOptionsType; label: string }[]
}>()

const radioId = defineModel<PaymentInfosOptionsType>('id', {
  required: true,
})

const i18n = useI18n()
</script>
