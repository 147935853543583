import type { MonetaryAmount } from '@backmarket/http-api/src/api-models'
import type { GetCustomerSalesPayload } from '@backmarket/http-api/src/api-specs-buyback/customer/getCustomerSales'
import type { BuybackModelAction } from '@backmarket/http-api/src/api-specs-buyback/models'
import {
  type FormattedStatusTimeline,
  type InfoBlockData,
  formatStatusTimeline,
  getInfoData,
  getStatusTimelineActiveStep,
} from '@backmarket/nuxt-layer-buyback/utils/adapters'

export interface SaleItemType {
  actions: BuybackModelAction[]
  id: number
  counterOfferPrice: MonetaryAmount | null
  price: MonetaryAmount
  title: string
  image: string
  tag: string
  timeline: {
    activeStep: string
    steps: FormattedStatusTimeline[]
  }
  infoBlockData: InfoBlockData | null
}

export interface MySalesFormatted {
  count: number
  next: string | null
  previous: string | null
  items: SaleItemType[]
}

export const mySalesAdapter = (
  apiResponse: GetCustomerSalesPayload,
): MySalesFormatted => {
  const { count, next, previous, results } = apiResponse

  const nextPageNumber = next ? new URL(next).searchParams.get('page') : null
  const prevPageNumber = previous
    ? new URL(previous).searchParams.get('page')
    : null

  return {
    count,
    next: nextPageNumber,
    previous: prevPageNumber,
    items: results?.map((item) => {
      const { actions } = item
      const { price, id } = item.order
      const counterOfferPrice = item.order.counterOfferPrice || null
      const { title } = item.product
      const image = item.product.image.light

      const steps = formatStatusTimeline(item.order.statusTimeline)

      const { tag, activeStep } = getStatusTimelineActiveStep(steps)

      const timeline = {
        activeStep,
        steps,
      }

      return {
        actions,
        id,
        counterOfferPrice,
        price,
        title,
        image,
        tag,
        timeline,
        infoBlockData: getInfoData(item.instruction),
      }
    }),
  }
}
