<template>
  <RevDrawer
    :back-button-label="i18n(modalBuybackTranslations.backButtonLabel)"
    :close-button-label="i18n(modalBuybackTranslations.close)"
    :has-back-button="showBackButton"
    :name="name"
    :title="activeStepTitle"
    @back="goToPrevStep"
    @close="handleClose"
  >
    <template #body>
      <div>
        <PersonalDataStep
          v-if="isPersonalDataStep"
          :formId="PERSONAL_DATA_FORM_ID"
          @toggle-next="(newState: boolean) => (isNextDisabled = newState)"
        />
        <ShippingStep
          v-if="isShippingStep"
          :id="id"
          :days-left="daysLeft"
          :prepaid-label="prepaidLabel"
          :qr-code-url="qrCodeUrl"
          :shipping-mode="shippingMode"
        />
      </div>
    </template>
    <template #footer>
      <RevButton
        :disabled="isNextDisabled"
        full-width="always"
        variant="primary"
        @click="handleFooterBtn"
      >
        {{ footerBtnLabel }}
      </RevButton>
    </template>
  </RevDrawer>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'

import { getCustomerSaleDetails } from '@backmarket/http-api/src/api-specs-buyback/customer/getCustomerSaleDetails'
import modalBuybackTranslations from '@backmarket/nuxt-layer-buyback/utils/Modal.translations'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevDrawer } from '@ds/components/Drawer'
import { closeModal } from '@ds/components/ModalBase'

import { getCustomerSaleDetailsAdapter } from '~/scopes/buyback/api/getCustomerSaleDetails/getCustomerSaleDetails.adapter'

import { SHIPPING_STEPS } from '../../SaleItem/constants'

import PersonalDataStep from './PersonalDataStep.vue'
import translations from './ShipItemModal.translations'
import ShippingStep from './ShippingStep.vue'

const PERSONAL_DATA_FORM_ID = 'personnal-data-form'
type ShipItemModalStep = 'personalDataStep' | 'shippingStep'

const props = withDefaults(
  defineProps<{
    name: string
    id: number
  }>(),
  {},
)

const emit = defineEmits(['is-loaded', 'close'])

const activeStep = ref<ShipItemModalStep>('personalDataStep')
const isNextDisabled = ref(true)

const i18n = useI18n()
const steps = computed(() => [
  {
    id: SHIPPING_STEPS.PersonalDataStep,
    label: i18n(translations.personalData),
  },
  {
    id: SHIPPING_STEPS.ShippingStep,
    label: i18n(translations.shipping),
  },
])

const { data, pending } = useHttpFetch(getCustomerSaleDetails, {
  pathParams: {
    id: props.id,
  },
  transform: getCustomerSaleDetailsAdapter,
})

const activeStepIndex = computed(() =>
  activeStep.value === 'personalDataStep' ? 0 : 1,
)

const countLabel = computed(() =>
  i18n(translations.stepCount, {
    step: activeStepIndex.value + 1,
    totalSteps: steps.value.length,
  }),
)

const activeStepTitle = computed(
  () => `${countLabel.value} ${steps.value[activeStepIndex.value].label}`,
)

const showBackButton = computed(() => activeStepIndex.value > 0)

const isPersonalDataStep = computed(
  () => activeStep.value === 'personalDataStep',
)

const isShippingStep = computed(() => activeStep.value === 'shippingStep')

const daysLeft = computed(() => data.value?.daysLeft)
const prepaidLabel = computed(() => data.value?.prepaidLabel)
const shippingMode = computed(() => data.value?.shippingMode)
const qrCodeUrl = computed(() => data.value?.qrCodeUrl)

const footerBtnLabel = computed(() => {
  if (isPersonalDataStep.value) {
    return i18n(translations.nextButton)
  }
  if (isShippingStep.value) {
    return i18n(translations.closeButton)
  }

  return ''
})

function handleClose() {
  emit('close')
}

function goToNextStep() {
  activeStep.value = 'shippingStep'
}

function goToPrevStep() {
  isNextDisabled.value = true
  activeStep.value = 'personalDataStep'
}

function handleFooterBtn() {
  if (activeStep.value === 'personalDataStep') {
    goToNextStep()
  } else {
    closeModal()
  }
}

watch(pending, (newVal) => {
  if (newVal === false) {
    emit('is-loaded')
  }
})
</script>
