import { type Link } from '@backmarket/http-api/src/api-models/Link'
import type {
  Address,
  CustomerSaleDetailsPayload,
} from '@backmarket/http-api/src/api-specs-buyback/customer/getCustomerSaleDetails'
import { useFormatAddress } from '@backmarket/nuxt-module-address/useFormatAddress'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { insertIf } from '@backmarket/utils/collection/insertIf'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import {
  SECTION_ID,
  type SectionType,
} from '../../dashboard/components/SaleDetails/constants'
import translations from '../../dashboard/components/SaleItem/SaleItem.translations'

export interface SectionItem {
  id: string
  label: string
  value: string | Array<string> | null
  info?: string | null
  link?: Link | string
}

export interface SaleDetailsSection {
  id: SectionType
  title: string
  items: SectionItem[] | null
}

export interface SaleDetails {
  details: Array<SaleDetailsSection>
  shippingMode: string | undefined
  daysLeft: number | undefined
  prepaidLabel: string | undefined
  id: number | undefined
  qrCodeUrl: string | null | undefined
}

export const getCustomerSaleDetailsAdapter = (
  apiResponse: CustomerSaleDetailsPayload,
): SaleDetails => {
  const i18n = useI18n()

  const { order, shipping } = apiResponse

  // Used by shipItemModal vue
  const shippingMode = shipping?.mode
  const daysLeft = order?.offerExpiryDays
  const prepaidLabel = shipping?.labelLink?.href
  const qrCodeUrl = shipping?.qrCodeUrl
  const { id } = order

  // Used by saleItemDetails vue
  const counterOfferPrice = order?.counterOfferPrice
    ? i18n.price(order.counterOfferPrice)
    : null

  const saleInfo: SaleDetailsSection = {
    id: SECTION_ID.SALE_INFO,
    title: i18n(translations.salesInfoTitle),
    items: [
      {
        id: translations.price.id,
        label: i18n(translations.price),
        value: i18n.price(order.price),
        info: counterOfferPrice,
      },
      {
        id: translations.orderId.id,
        label: i18n(translations.orderId),
        value: order.id?.toString(),
      },
      {
        id: translations.refurbisher.id,
        label: i18n(translations.refurbisher),
        value: order?.merchantCompany ?? null,
      },
    ],
  }

  const assessment: SaleDetailsSection = {
    id: SECTION_ID.ASSESSMENT,
    title: i18n(translations.assessmentTitle),
    items:
      order?.customerAssessment && !isEmpty(order.customerAssessment)
        ? order.customerAssessment.map(({ name, ...rest }) => ({
            id: name,
            ...rest,
          }))
        : null,
  }

  const documents: SaleDetailsSection = {
    id: SECTION_ID.DOCUMENTS,
    title: i18n(translations.documentsTitle),
    items: [
      insertIf(Boolean(shipping?.qrCodeUrl), {
        id: translations.shippingQrCode.id,
        label: i18n(translations.shippingQrCode),
        value: '',
        link: shipping?.qrCodeUrl,
      }) as SectionItem,
      {
        id: translations.shippingLabel.id,
        label: i18n(translations.shippingLabel),
        value: '',
        link: shipping?.labelLink,
      },
      {
        id: translations.saleVoucher.id,
        label: i18n(translations.saleVoucher),
        value: '',
        link: order.transferCertificateLink,
      },
    ],
  }

  const returnAddress = shipping?.returnAddress
  const deliveryAddress = shipping?.deliveryAddress

  const isAddressValid = (address: Address | undefined) => {
    return (
      address &&
      Boolean(
        // mandatory keys for useFormatAddress
        // needed because some legacy order do not have addresses
        address.city &&
          address.country &&
          address.firstName &&
          address.lastName &&
          address.postalCode &&
          address.street,
      )
    )
  }

  const shippingInfo: SaleDetailsSection = {
    id: SECTION_ID.SHIPPING,
    title: i18n(translations.shippingInfoTitle),
    items: [
      {
        id: translations.from.id,
        label: i18n(translations.from),
        value:
          returnAddress && isAddressValid(returnAddress)
            ? useFormatAddress(returnAddress)
            : null,
      },
      {
        id: translations.to.id,
        label: i18n(translations.to),
        value:
          deliveryAddress && isAddressValid(returnAddress)
            ? useFormatAddress(deliveryAddress)
            : null,
      },
      {
        id: translations.shippingMode.id,
        label: i18n(translations.shippingMode),
        value: shipping?.modeLabel ?? null,
      },
      {
        id: translations.carrier.id,
        label: i18n(translations.carrier),
        value: shipping?.shipperName ?? null,
      },
      {
        id: translations.parcelNumber.id,
        label: i18n(translations.parcelNumber),
        value: shipping?.trackingNumber?.label ?? null,
        link: shipping?.trackingNumber?.link,
      },
    ],
  }

  const cleanResults = (section: SaleDetailsSection) => {
    const cleaned =
      section.items &&
      section.items.filter(
        (item) => !isEmpty(item.value) || !isEmpty(item.link),
      )

    return { ...section, items: cleaned }
  }

  const details = [saleInfo, assessment, documents, shippingInfo]
    .map(cleanResults)
    .filter((section) => !isEmpty(section))

  return {
    shippingMode,
    daysLeft,
    prepaidLabel,
    details,
    id,
    qrCodeUrl,
  }
}
