<template>
  <RevListItemStatic>
    <template #label>
      <span class="text-static-default-low">{{ item?.label }}</span>
    </template>
    <template #suffix>
      <span v-if="isArray" class="text-static-default-hi ml-auto flex flex-col">
        <span
          v-for="value in item?.value"
          :key="value"
          class="body-2-bold text-right"
        >
          {{ value }}
        </span>
      </span>
      <RevLink v-else-if="isLink" target="_blank" :to="item.link">
        {{ item?.value }}
      </RevLink>
      <template v-else>
        <div class="flex items-center">
          <span :class="itemValueClass">
            {{ item.value }}
          </span>
          <span
            v-if="item.info"
            class="text-static-default-hi body-2-bold ml-auto mr-2"
          >
            {{ item.info }}
          </span>
        </div>
      </template>
    </template>
  </RevListItemStatic>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { tw } from '@backmarket/utils/string/tw'
import { RevLink } from '@ds/components/Link'
import { RevListItemStatic } from '@ds/components/ListItemStatic'

import type { SectionItem } from '~/scopes/buyback/api/getCustomerSaleDetails/getCustomerSaleDetails.adapter'

interface SaleDetailsProps {
  item: SectionItem
}

const props = defineProps<SaleDetailsProps>()

const isArray = computed(() => Array.isArray(props.item?.value))
const isLink = computed(() => Boolean(props.item?.link))
const isCounterOfferPrice = computed(
  () =>
    props.item?.id === 'buyback_my_sales_details_price' &&
    Boolean(props.item?.info),
)
const itemValueClass = computed(() => [
  tw`text-static-default-hi body-2-bold ml-auto mr-2`,
  isCounterOfferPrice.value ? tw`caption-striked line-through order-2` : '',
])
</script>
