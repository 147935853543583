<template>
  <div>
    <h3 class="heading-3 mt-7">{{ i18n(translations.title) }}</h3>
    <p class="text-static-default-low body-1 mt-12">
      {{ i18n(translations.subTitle) }}
    </p>
    <RevList class="mt-32" :hasExternalBorders="false" interactive>
      <RevListItemInteractive
        v-for="item in userInfoList"
        :key="item.id"
        @click="displayForm(item.id)"
      >
        <template #prefix v-if="item.prefix">
          <component :is="item.prefix" class="text-action-success-hi" />
        </template>

        <template #label>
          {{ item.title }}
        </template>

        <template #description v-if="item.requiredLabel">
          {{ item.requiredLabel }}
        </template>

        <template #suffix v-if="item.suffix">
          <component :is="item.suffix" />
        </template>
      </RevListItemInteractive>
    </RevList>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { PaymentInfosTypesType } from '@backmarket/http-api/src/api-specs-buyback/customer/client'
import {
  KYC_IDS,
  type KycType,
  useBuybackConfig,
} from '@backmarket/nuxt-layer-buyback/composables/config/useBuybackConfig'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevList } from '@ds/components/List'
import { RevListItemInteractive } from '@ds/components/ListItemInteractive'
import { IconCheckInCircle } from '@ds/icons/IconCheckInCircle'
import { IconChevronRight } from '@ds/icons/IconChevronRight'
import { IconPlus } from '@ds/icons/IconPlus'

import kycTranslations from '../KycDetailsModal.translations'

import translations from './Intro.translations'

const i18n = useI18n()
const emit = defineEmits<{
  next: [id: KycType]
}>()
const props = defineProps<{
  hasIdentityInformations: boolean
  hasIdentity: boolean
  hasBirthdate: boolean
  hasBankDetails: boolean
  paymentTypes: PaymentInfosTypesType[] | undefined
}>()

const kycToPaymentInfo = {
  BANK_DETAILS: KYC_IDS.BANK_DETAILS,
  IDENTITY_DOCUMENT: KYC_IDS.IDENTITY,
  PERSONAL_IDENTIFICATION: KYC_IDS.IDENTITY_INFORMATIONS,
  DATE_OF_BIRTH: KYC_IDS.BIRTHDATE,
}

const sections = props.paymentTypes?.map((type) => kycToPaymentInfo[type])

const introSection = computed(() => ({
  [KYC_IDS.BANK_DETAILS]: {
    title: i18n(translations.bankTitle),
    completed: props.hasBankDetails,
  },
  [KYC_IDS.IDENTITY_INFORMATIONS]: {
    title: i18n(kycTranslations.identityInformationsTitle),
    completed: props.hasIdentityInformations,
  },
  [KYC_IDS.SSN]: {
    title: i18n(translations.fiscalCodeTitle),
    completed: props.hasIdentityInformations,
  },
  [KYC_IDS.BIRTHDATE]: {
    title: i18n(translations.birthdateTitle),
    completed: props.hasBirthdate,
  },
  [KYC_IDS.IDENTITY]: {
    title: i18n(translations.idTitle),
    completed: props.hasIdentity,
  },
}))

const userInfoList = computed(() => {
  const { kyc } = useBuybackConfig()
  const infoList: Array<{
    id: KycType
    title: string
    requiredLabel: string | null
    prefix: object | ''
    suffix: object
  }> = []
  Object.values(KYC_IDS)
    .filter((value) => value !== KYC_IDS.SSN && sections?.includes(value))
    .forEach((value) => {
      const currentSection = introSection.value[value as KycType]
      const currentKyc = kyc[value as KycType]
      infoList.push({
        id: value,
        title: currentSection.title,
        requiredLabel:
          currentKyc?.required && !currentSection.completed
            ? i18n(translations.requiredLabel)
            : null,
        prefix: currentSection.completed ? IconCheckInCircle : '',
        suffix: !currentSection.completed ? IconPlus : IconChevronRight,
      })
    })

  return infoList
})

function displayForm(id: KycType) {
  emit('next', id)
}
</script>
