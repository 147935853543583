<template>
  <div v-if="!pending">
    <template v-if="showForm">
      <IdentityInformationsChoice
        v-show="step === 'choice'"
        v-model:id="id"
        :ids="ids"
      />
      <IdentityInformationsDetails
        v-show="step === 'details'"
        :id="id"
        :form-id="formId"
        @submit="handleIdentityInformations"
      />
    </template>
    <div v-else>
      <p class="text-static-default-low body-1 mb-3 mt-4">
        {{ alreadySentTitle }}
      </p>
      <RevList class="mt-22" hasExternalBorders>
        <RevListItemStatic>
          <template #label>
            {{ alreadySentDescription }}
          </template>
          <template #description>
            {{ data?.idNumber }}
          </template>
        </RevListItemStatic>
      </RevList>
    </div>
  </div>
  <div v-else>
    <RevLoadingScreen :text="i18n(translationsKyc.loadingDetails)" />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import {
  PAYMENT_INFOS_OPTIONS,
  type PaymentInfo,
  type PaymentInfosOptionsType,
} from '@backmarket/http-api/src/api-specs-buyback/customer/client'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevList } from '@ds/components/List'
import { RevListItemStatic } from '@ds/components/ListItemStatic'
import { RevLoadingScreen } from '@ds/components/LoadingScreen'

import { type FormValues } from '~/scopes/buyback/components/FormGenerator/FormGenerator.vue'
import { useIdentityInformations } from '~/scopes/dashboard/my-sales/composables/useKycInformations'

import translationsKyc from '../KycDetailsModal.translations'

import translations from './IdentityInformations.translations'
import IdentityInformationsChoice from './IdentityInformationsChoice.vue'
import IdentityInformationsDetails from './IdentityInformationsDetails.vue'

const props = defineProps<{
  showForm: boolean
  formId: string
  step: 'choice' | 'details'
  identityInfos: PaymentInfo | undefined
}>()
const emit = defineEmits(['submit', 'last-step'])

const i18n = useI18n()

const { data, pending } = useIdentityInformations()

const id = ref<PaymentInfosOptionsType>(
  props.identityInfos?.options[0] ?? PAYMENT_INFOS_OPTIONS.SSN,
)

if (props.identityInfos?.options.length === 1) {
  emit('last-step')
}

function inputsTitle(option: PaymentInfosOptionsType) {
  switch (option) {
    case PAYMENT_INFOS_OPTIONS.SSN:
      return translations.ssn
    case PAYMENT_INFOS_OPTIONS.DRIVING_LICENCE_NUMBER:
      return translations.drivingLicense
    default:
      return translations.drivingLicense
  }
}

const ids =
  props.identityInfos?.options.map((option) => {
    return {
      id: option,
      label: i18n(inputsTitle(option)),
    }
  }) || []

function handleIdentityInformations(values: FormValues) {
  if (values.drivingLicense && typeof values.drivingLicense === 'string') {
    emit('submit', values.drivingLicense, 'driving_license')
  }
  if (values.ssn && typeof values.ssn === 'string') {
    emit('submit', values.ssn, 'social_security')
  }
}

const alreadySentTitle = computed(() => {
  return data.value?.idType === 'social_security'
    ? i18n(translations.alreadySentTitleSsn)
    : i18n(translations.alreadySentTitleDrivingLicense)
})
const alreadySentDescription = computed(() => {
  return data.value?.idType === 'social_security'
    ? i18n(translations.alreadySentDescriptionSsn)
    : i18n(translations.alreadySentDescriptionDrivingLicense)
})
</script>
