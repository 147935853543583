<template>
  <div>
    <RevInfoBlock
      v-if="hasInfoBlock"
      class="mb-32"
      :content="item?.infoBlockData?.content"
      :icon="getInfoIcon(item?.infoBlockData?.icon)"
      :title="String(item?.infoBlockData?.title)"
      :variant="'info'"
    />
    <RevCard class="pt-24">
      <div class="md:pt-7">
        <div
          class="relative isolate z-[1] px-16 md:flex md:flex-col md:px-32 lg:flex-row lg:gap-32"
        >
          <div class="z-10 md:mr-7 md:flex md:grow md:flex-col">
            <ProductInfo
              :counter-offer-price="formatPrice(item?.counterOfferPrice)"
              :image="item?.image"
              :price="formatPrice(item?.price)"
              :tag="item?.tag"
              :title="item?.title"
            />
            <RevDivider class="mb-24 mt-16 md:hidden" />
            <div class="md:mt-16 lg:ml-[84px] lg:mt-24">
              <ClientOnly>
                <template #placeholder>
                  <StatusTrackerSkeleton :steps="item?.timeline.steps" />
                </template>
                <template #default>
                  <RevStatusTracker
                    :key="item?.timeline.steps.length"
                    :current-step="item?.timeline.activeStep"
                    :orientation="statusTrackerOrientation"
                    progress-bar-color="accent"
                    :steps="item?.timeline?.steps"
                  >
                    <template
                      v-for="step in item?.timeline.steps"
                      :key="step.key"
                      #[`${step.key}-date`]
                    >
                      <p>
                        {{ step.date }}
                      </p>
                    </template>

                    <template
                      v-for="step in item?.timeline.steps"
                      :key="step.key"
                      #[`${step.key}-details`]
                    >
                      <p class="text-static-default-low">
                        {{ step.details }}
                      </p>
                    </template>
                  </RevStatusTracker>
                </template>
              </ClientOnly>
            </div>
          </div>
          <div
            v-if="hasCtaBlock"
            class="mt-16 flex flex-col gap-12 md:mt-24 lg:mt-0 lg:w-[287px]"
          >
            <RevButton
              v-for="action in actionList"
              :key="action.id"
              :data-qa="`orders-button-${action.id}`"
              full-width="always"
              v-bind="action.props"
              @click="action.event"
            >
              {{ action.label }}
            </RevButton>
          </div>
        </div>
        <div class="relative pt-20">
          <RevDivider class="absolute -inset-x-0 m-0" />
        </div>
      </div>

      <ShipItemModal
        v-if="modalStatus === 'shipOrder'"
        :id="item.id"
        :name="shipItemModalName"
        @close="closeModal"
        @is-loaded="showModal"
      />

      <KycDetailsModalNew
        v-if="modalStatus === 'addBankDetails' && FF_ENABLE_NEW_KYC_MODAL"
        :id="item.id"
        :name="bankDetailsModalName"
        @close="closeModal"
        @is-loaded="showModal"
      />
      <KycDetailsModal
        v-if="modalStatus === 'addBankDetails' && !FF_ENABLE_NEW_KYC_MODAL"
        :id="item.id"
        :name="bankDetailsModalName"
        @close="closeModal"
        @is-loaded="showModal"
      />

      <CounterOfferModal
        v-if="modalStatus === 'respondToCounterOffer'"
        :id="item.id"
        :name="counterOfferModalName"
        @close="closeModal"
        @is-loaded="showModal"
        @reject="openConfirmCounterOfferModal"
        @reload-sales="emit('reload-sales', item.id)"
      />

      <ConfirmCounterOfferModal
        :id="item.id"
        :name="confirmCounterOfferModalName"
        @cancel="cancelConfirmCounterOfferModal"
        @reload-sales="emit('reload-sales', item.id)"
      />

      <SaleDetails :item-id="item?.id" />
    </RevCard>
  </div>
</template>

<script setup lang="ts">
import { useRuntimeConfig } from '#imports'
import { type Component, computed, ref } from 'vue'

import type { MonetaryAmount } from '@backmarket/http-api/src/api-models/MonetaryAmount'
import ProductInfo from '@backmarket/nuxt-layer-buyback/components/ProductInfo/ProductInfo.vue'
import StatusTrackerSkeleton from '@backmarket/nuxt-layer-buyback/components/StatusTrackerSkeleton/StatusTrackerSkeleton.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useBreakpoint } from '@backmarket/utils/composables/useBreakpoint'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevButton } from '@ds/components/Button'
import { VariantsEnum } from '@ds/components/Button/Button.constant'
import { RevCard } from '@ds/components/Card'
import { RevDivider } from '@ds/components/Divider'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { openModal } from '@ds/components/ModalBase'
import {
  type Orientations,
  RevStatusTracker,
} from '@ds/components/StatusTracker'
import { IconArchive } from '@ds/icons/IconArchive'
import { IconContact } from '@ds/icons/IconContact'
import { IconCreditCard } from '@ds/icons/IconCreditCard'
import { IconMoney } from '@ds/icons/IconMoney'

import type { SaleItemType } from '~/scopes/buyback/api/getCustomerSales/mySalesAdapter'
import { MODAL_NAMES } from '~/scopes/buyback/constants'

import ConfirmCounterOfferModal from '../Modals/CounterOffer/ConfirmCounterOfferModal.vue'
import CounterOfferModal from '../Modals/CounterOffer/CounterOfferModal.vue'
import KycDetailsModal from '../Modals/KycDetails/KycDetailsModal.vue'
import KycDetailsModalNew from '../Modals/KycDetailsNew/KycDetailsModal.vue'
import ShipItemModal from '../Modals/ShipItem/ShipItemModal.vue'
import SaleDetails from '../SaleDetails/SaleDetails.vue'

interface SaleItemProps {
  item: SaleItemType
}

type Icons = 'box' | 'card' | 'bubble' | 'mat'
type ModalType =
  | 'idle'
  | 'shipOrder'
  | 'addBankDetails'
  | 'confirmCounterOffer'
  | 'respondToCounterOffer'

type IconsMap = {
  [key in Icons]: Component
}

const MY_SALES_ICONS: IconsMap = {
  box: IconArchive,
  card: IconCreditCard,
  bubble: IconContact,
  mat: IconMoney,
}
const BUTTON_ACTIONS = {
  SHIP_ORDER: 'shipOrder',
  GET_HELP: 'getHelp',
  FOLLOW_MY_BUYBACK: 'followMyBuyback',
  ADD_KYC_DETAILS: 'addBankDetails',
  TRACK_ORDER: 'trackOrder',
  RESPOND_TO_COUNTER_OFFER: 'respondToCounterOffer',
  SOS: 'sos',
}

const emit = defineEmits(['reload-sales'])
const props = defineProps<SaleItemProps>()
const i18n = useI18n()
const breakpoint = useBreakpoint()
const runtimeConfig = useRuntimeConfig()

const hasInfoBlock = computed(() => !isEmpty(props.item?.infoBlockData))
const hasCtaBlock = computed(() => !isEmpty(props.item?.actions))
const statusTrackerOrientation = computed<Orientations>(() =>
  breakpoint.value < 2 ? 'vertical' : 'horizontal',
)
const { FF_ENABLE_NEW_KYC_MODAL } = runtimeConfig.public

const shipItemModalName = computed(
  () => `${MODAL_NAMES.MY_SALES_SHIP_ITEM}-${props.item.id}`,
)
const bankDetailsModalName = computed(
  () => `${MODAL_NAMES.MY_SALES_KYC_DETAILS}-${props.item.id}`,
)
const counterOfferModalName = computed(
  () => `${MODAL_NAMES.MY_SALES_COUNTER_OFFER}-${props.item.id}`,
)
const confirmCounterOfferModalName = computed(
  () => `${MODAL_NAMES.MY_SALES_CONFIRM_COUNTER_OFFER}-${props.item.id}`,
)

const modalStatus = ref<ModalType>('idle')
const isLoading = ref(false)
const modalsMap: Record<ModalType, string> = {
  shipOrder: shipItemModalName.value,
  addBankDetails: bankDetailsModalName.value,
  respondToCounterOffer: counterOfferModalName.value,
  confirmCounterOffer: confirmCounterOfferModalName.value,
  idle: '',
}

function loadModal(modalType: ModalType) {
  isLoading.value = true
  modalStatus.value = modalType
}

function showModal() {
  isLoading.value = false
  openModal(modalsMap[modalStatus.value])
}

function closeModal() {
  modalStatus.value = 'idle'
}

function openConfirmCounterOfferModal() {
  loadModal('confirmCounterOffer')
  showModal()
}

function cancelConfirmCounterOfferModal() {
  modalStatus.value = 'respondToCounterOffer'
  showModal()
}

const actionList = computed(() => {
  const newTabActions = [BUTTON_ACTIONS.TRACK_ORDER, BUTTON_ACTIONS.GET_HELP]
  const actionsEvents = {
    [BUTTON_ACTIONS.SHIP_ORDER]: () => {
      loadModal('shipOrder')
    },
    [BUTTON_ACTIONS.ADD_KYC_DETAILS]: () => {
      loadModal('addBankDetails')
    },
    [BUTTON_ACTIONS.RESPOND_TO_COUNTER_OFFER]: () => {
      loadModal('respondToCounterOffer')
    },
  }

  return props.item?.actions.map(({ id, label, link }, index) => {
    return {
      id,
      props: {
        variant: index === 0 ? VariantsEnum.Primary : VariantsEnum.Secondary,
        to: link?.href,
        target: newTabActions.includes(id) ? '_blank' : '_self',
        loading: isLoading.value && modalStatus.value === id,
      },
      label,
      event: actionsEvents[id] ?? (() => null),
    }
  })
})

function getInfoIcon(iconId: string | undefined): Component {
  // Double checking that iconId matches Icons type
  if (iconId && ['box', 'card', 'bubble', 'mat'].includes(iconId)) {
    return MY_SALES_ICONS[iconId as Icons]
  }

  return {}
}

function formatPrice(price: MonetaryAmount | null): string {
  if (price) {
    return i18n.price(price)
  }

  return ''
}
</script>
