<template>
  <div>
    <div v-if="isPrepaidLabel || isQrCode" class="flex flex-col gap-16">
      <h3 class="heading-3">
        {{
          hasQrCodeShippingStepCountries
            ? i18n(translations.prepaidQrCodeLabelTitle)
            : i18n(translations.prepaidLabelTitle)
        }}
      </h3>
      <p class="text-static-default-low body-1">
        {{
          hasQrCodeShippingStepCountries
            ? i18n(translations.prepaidQrCodeLabelParagraph)
            : i18n(translations.prepaidLabelFirstParagraph)
        }}
      </p>
      <div
        class="flex gap-24"
        :class="{
          'flex-col': isPrepaidLabel,
          'flex-col-reverse': isQrCode,
        }"
      >
        <div class="flex flex-col gap-12">
          <p v-if="isPrepaidLabel" class="text-static-default-low body-1 mb-12">
            {{ i18n(translations.prepaidLabelParagraph) }}
          </p>

          <RevButton
            full-width="always"
            variant="secondary"
            @click="sendShippingLabelByEmail"
          >
            {{ i18n(translations.emailPrepaidLabel) }}
          </RevButton>

          <RevButton
            v-if="hasPrepaidLabel"
            full-width="always"
            target="_blank"
            :to="prepaidLabel"
            :variant="isPrepaidLabel ? 'primary' : 'secondary'"
          >
            {{ i18n(translations.downloadShippingLabel) }}
          </RevButton>
        </div>

        <div
          v-if="qrCodeUrl && hasQrCodeShippingStepCountries"
          class="body-1-bold flex w-full items-center gap-12"
        >
          <RevDivider class="grow" />
          <span> {{ i18n(translations.shippingStepSeparator) }} </span>
          <RevDivider class="grow" />
        </div>

        <div
          v-if="qrCodeUrl && hasQrCodeShippingStepCountries"
          class="body-2 flex flex-col gap-16"
        >
          <div
            class="border-static-brand-mid rounded-sm overflow-hidden border"
          >
            <div class="bg-surface-brand-mid body-1-bold px-64 py-12">
              {{ i18n(translations.shippingStepQrCodeShippingLabel) }}
            </div>
            <div class="px-72 py-32">
              <RevIllustration
                :alt="i18n(translations.shippingStepQrCodeShippingLabel)"
                class="m-auto"
                :height="180"
                sizes="180px"
                :src="qrCodeUrl"
                :width="180"
              />
            </div>
          </div>
          <RevButton
            v-if="isQrCode"
            full-width="always"
            target="_blank"
            :to="i18n(translations.shippingStepQrCodeLink)"
            variant="primary"
          >
            {{ i18n(translations.shippingStepQrCodeLinkText) }}
          </RevButton>
          <RevLink
            v-else
            target="_blank"
            :to="i18n(translations.shippingStepQrCodeLink)"
          >
            {{ i18n(translations.shippingStepQrCodeLinkText) }}
          </RevLink>
        </div>
      </div>
    </div>

    <div v-if="isKit">
      <h3 class="heading-3">
        {{ i18n(translations.kitTitle) }}
      </h3>
      <p class="text-static-default-low body-1 mt-16">
        {{ i18n(translations.kitFirstParagraph) }}
      </p>
      <p class="text-static-default-low body-1 mt-24">
        {{ i18n(translations.kitSecondParagraph) }}
      </p>

      <RevAccordionList
        v-if="hasPrepaidLabel"
        class="mt-56"
        :has-dividers="false"
      >
        <RevAccordionItem>
          <template #title>
            {{ i18n(translations.kitNotReceived) }}
          </template>
          <template #body>
            <p class="text-static-default-low body-2">
              {{ i18n(translations.kitNotReceivedParagraph) }}
            </p>
            <RevLink
              class="text-static-default-low mt-24"
              target="_blank"
              :to="prepaidLabel"
            >
              {{ i18n(translations.kitNotReceivedLink) }}
            </RevLink>
          </template>
        </RevAccordionItem>
      </RevAccordionList>
    </div>

    <RevInfoBlock
      v-if="showInfoBlock"
      class="mt-32"
      :icon="IconSiren"
      :title="i18n(translations.infoBlockContent, { count: daysLeft })"
      variant="default"
    />
  </div>
</template>

<script lang="ts" setup>
import { useRuntimeConfig } from '#imports'
import { computed } from 'vue'

import { BUYBACK_SHIPPING_MODE } from '@backmarket/http-api/src/api-specs-buyback/constants'
import { postShippingLabelEmail } from '@backmarket/http-api/src/api-specs-buyback/customer/postShippingLabelEmail'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevAccordionItem } from '@ds/components/AccordionItem'
import { RevAccordionList } from '@ds/components/AccordionList'
import { RevButton } from '@ds/components/Button'
import { RevDivider } from '@ds/components/Divider'
import { RevIllustration } from '@ds/components/Illustration'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { RevLink } from '@ds/components/Link'
import { IconSiren } from '@ds/icons/IconSiren'

import translations from './ShippingStep.translations'

const props = defineProps<{
  shippingMode: string | undefined
  prepaidLabel: string | undefined
  daysLeft: number | undefined
  id: number
  qrCodeUrl: string | null | undefined
}>()

const i18n = useI18n()
const { openSuccessToast, openErrorToast } = useTheToast()
const config = useRuntimeConfig()
const country = useMarketplace().market.countryCode

const hasQrCodeShippingStepCountries =
  config.public.FF_QR_CODE_SHIPPING_STEP_COUNTRIES.includes(country)

const isPrepaidLabel = computed(
  () => props.shippingMode === BUYBACK_SHIPPING_MODE.SELF_PACKAGED,
)
const hasPrepaidLabel = computed(() => !isEmpty(props.prepaidLabel))
const isQrCode = computed(
  () => props.shippingMode === BUYBACK_SHIPPING_MODE.QR_CODE,
)
const isKit = computed(() => props.shippingMode === BUYBACK_SHIPPING_MODE.KIT)
const showInfoBlock = computed(() => props.daysLeft !== undefined)

async function sendShippingLabelByEmail() {
  const { status } = await useHttpFetch(postShippingLabelEmail, {
    pathParams: {
      id: props.id,
    },
  })

  if (status.value === 'success') {
    openSuccessToast({
      title: i18n(translations.toastSuccessContent),
    })
  } else {
    openErrorToast({
      title: i18n(translations.toastErrorContent),
    })
  }
}
</script>
