import type {
  BuybackModelInstruction,
  BuybackModelTimeline,
} from '@backmarket/http-api/src/api-specs-buyback/models'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { spacesToHyphens } from '@backmarket/utils/string/spacesToHyphens'
import { type Step } from '@ds/components/StatusTracker'

export const NO_DATA_STRING = '-'

export type FormattedStatusTimeline = Step & {
  current: boolean
  tag: string
  hint: string | null
  date: string | null
  details: string | null
}

export interface InfoBlockData {
  content: string
  icon: string
  title: string
  variant: string
}

export const formatStatusTimeline = (
  statusTimeline: readonly BuybackModelTimeline[],
): FormattedStatusTimeline[] => {
  const i18n = useI18n()

  return statusTimeline.map((step) => ({
    current: step.current,
    tag: step.tag,
    hint: step.hint || '',
    label: step.label,
    key: spacesToHyphens(step.label.toLowerCase()),
    date: step.date
      ? i18n.date(new Date(step.date), {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        })
      : '',
    details: step.info,
  }))
}

export const getStatusTimelineActiveStep = (
  statusTimeline: FormattedStatusTimeline[],
) => {
  const { tag, key: activeStep } =
    statusTimeline.find((status) => status.current) || statusTimeline[0]

  return { tag, activeStep }
}

export const getInfoData = (
  instruction: BuybackModelInstruction | undefined,
): InfoBlockData | null => {
  if (isEmpty(instruction)) {
    return null
  }
  const { body, icon, title: bannerTitle } = instruction

  return {
    content: body,
    icon,
    title: bannerTitle,
    variant: 'info',
  }
}
